import React from 'react';
import Lottie from 'lottie-react';
import recruitmentAnimation from './recruitment-animation.json'; // You'll need to download this JSON file

const RecruitmentAnimation = () => {
  const style = {
    height: 400,
    width: 400,
  };

  return (
    <Lottie 
      animationData={recruitmentAnimation} 
      style={style}
      loop={true}
    />
  );
};

export default RecruitmentAnimation;