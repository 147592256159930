import React from 'react';
import { observer, inject } from 'mobx-react';

const TableTile = ({ data }) => {
  if (data === null || data === '') {
    return null;
  }
//  const data = store.storeOutput;

  let parsedData;
  try {
    parsedData = JSON.parse(data);
  } catch (error) {
    console.error(`Error parsing JSON data: ${error}`);
    // Attempt to fix the JSON string by adding a closing curly bracket
    const fixedData = data + '}';
    try {
      parsedData = JSON.parse(fixedData);
    } catch (error) {
      console.error(`Error parsing fixed JSON data: ${error}`);
      return <div>Error parsing JSON data</div>;
    }
  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      {Object.entries(parsedData).map(([key, value]) => {
        let displayValue;
        if (Array.isArray(value)) {
          displayValue = value.join(', ');
        } else {
          displayValue = value;
        }

        return (
          <div key={key} className="flex flex-row mb-2">
            <div className="font-bold mr-2">{key}:</div>
            <div>{displayValue}</div>
          </div>
        );
      })}
    </div>
  );
};

export default inject('store')(observer(TableTile));