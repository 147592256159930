import React, { useState, useEffect, useCallback } from 'react';
import { FaEye, FaChevronDown } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';

const InterviewResultModal = ({ interview, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
    <div className="relative bg-white rounded-lg shadow-xl w-full max-w-lg my-8">
      <div className="sticky top-0 bg-white p-4 sm:p-6 border-b border-gray-200 flex justify-between items-center rounded-t-lg">
        <h2 className="text-xl sm:text-2xl font-semibold truncate pr-8">
          {interview.candidateName} - {interview.jobTitle}
        </h2>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors duration-300"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div className="p-4 sm:p-6">
        <div className="mb-4">
          <p className="font-medium">Interview Date: <span className="font-normal">{interview.date}</span></p>
          <p className="font-medium">Overall Result: 
            <span className={`font-normal ${interview.evaluation.isSuitable ? 'text-green-600' : 'text-red-600'}`}>
              {interview.evaluation.isSuitable ? ' Suitable' : ' Not Suitable'}
            </span>
          </p>
          <p className="font-medium">Overall Score: <span className="font-normal">{interview.evaluation.overallScore}/10</span></p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Evaluation</h3>
          <p className="text-gray-700">{interview.evaluation.explanation}</p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Strengths</h3>
          <ul className="list-disc list-inside">
            {interview.evaluation.strengths.map((strength, index) => (
              <li key={index} className="text-gray-700">{strength}</li>
            ))}
          </ul>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-medium mb-2">Areas for Improvement</h3>
          <ul className="list-disc list-inside">
            {interview.evaluation.areasForImprovement.map((area, index) => (
              <li key={index} className="text-gray-700">{area}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

const CompletedInterviews = inject('store')(observer(({ store }) => {
  const [selectedJob, setSelectedJob] = useState('');
  const [interviews, setInterviews] = useState([]);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const refreshJobList = useCallback(async () => {
    try {
      setIsLoading(true);
      let response = await store.api.post('/user/JobDetails/view');
      store.joblist = response.data.map((job) => ({
        jobid: job.jobId,
        jobname: job.jobname,
        jobdescription: job.jobdescription,
      }));
    } catch (error) {
      console.error("Failed to fetch job list:", error);
    } finally {
      setIsLoading(false);
    }
  }, [store]);

  const fetchCompletedInterviews = async () => {
    try {
      setIsLoading(true);
      const response = await store.api.get('/user/completed-interviews');
      setInterviews(response.data);
    } catch (error) {
      console.error('Error fetching completed interviews:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshJobList();
    fetchCompletedInterviews();
  }, [refreshJobList]);

  const handleJobChange = (e) => {
    setSelectedJob(e.target.value);
  };

  const handleViewResult = async (interviewId) => {
    try {
      const response = await store.api.get(`/user/completed-interviews/${interviewId}`);
      setSelectedInterview(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching interview details:', error);
    }
  };

  const filteredInterviews = selectedJob
    ? interviews.filter(interview => interview.jobId === parseInt(selectedJob))
    : interviews;

  return (
    <div className="container mx-auto px-4 py-8">
      
      <div className="mb-6">
        <label htmlFor="jobSelect" className="block text-sm font-medium text-gray-700 mb-2">
          Select Job
        </label>
        <div className="relative">
          <select
            id="jobSelect"
            value={selectedJob}
            onChange={handleJobChange}
            className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="">All Jobs</option>
            {store.joblist.map(job => (
              <option key={job.jobid} value={job.jobid}>{job.jobname}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <FaChevronDown />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Candidate
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Job Title
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Result
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Score
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredInterviews.map(interview => (
                <tr key={interview.id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.candidateName}</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.jobTitle}</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.date}</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <span className={`relative inline-block px-3 py-1 font-semibold ${
                      interview.result === 'Suitable' ? 'text-green-900' : 'text-red-900'
                    } leading-tight`}>
                      <span aria-hidden className={`absolute inset-0 ${
                        interview.result === 'Suitable' ? 'bg-green-200' : 'bg-red-200'
                      } opacity-50 rounded-full`}></span>
                      <span className="relative">{interview.result}</span>
                    </span>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">{interview.overallScore}/10</p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <button
                      onClick={() => handleViewResult(interview.id)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <FaEye className="inline-block mr-1" /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && selectedInterview && (
        <InterviewResultModal
          interview={selectedInterview}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}));

export default CompletedInterviews;