import React, { useState, useEffect,useRef } from 'react';
import SingleSelect from './EntryDropdown';
import EntryText  from './EntryText'
import {
  PencilIcon,
  LightBulbIcon,
} from '@heroicons/react/outline'

import { inject, observer } from 'mobx-react';
//import Tesseract from 'tesseract.js';
import mammoth from 'mammoth';
import { Rings } from 'react-loader-spinner';
import FileUpload from './FileUpload';
import JobList from './JobList';
import { Dropdown } from 'primereact/dropdown';
import Button from '../Components/Button'
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import Countdown from 'react-countdown';

const ScreenCandidate = ({ store }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isNewJob, setIsNewJob] = useState(false); // State to track if "Create New" is selected
  const [newJobName, setNewJobName] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [fileContent, setfileContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);


  useEffect(() => {
    refreshJobList();
  }, []);

  const refreshJobList = async () => {
    let response = await store.api.post('/user/JobDetails/view');
    store.joblist = response.data.map((job) => ({
      jobid: job.jobId,
      jobname: job.jobname,
      jobdescription: job.jobdescription,
    }));
  };

  const handlePdfUpload = async (file) => {
    // pass file to server and get text
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const response = await store.api.post('user/extract/pdf', { file: reader.result });
      setfileContent(response.data.text);
    };
  // server side code to extract text from pdf

    // const reader = new FileReader();
    // reader.readAsArrayBuffer(file);
    // reader.onload = async () => {
    //   const pdfData = new Uint8Array(reader.result);
    //   const pdfDoc = await PDFDocument.load(pdfData);
    //   const pages = pdfDoc.getPages();
    //   const text = await Promise.all(
    //     pages.map(async (page) => {
    //       const content = await page.getText();
    //       return content.items.map((item) => item.str).join('');
    //     })
    //   );
    //   setfileContent(text.join(''));
    // };
  };

  const handleDocUpload = async (file) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const result = await mammoth.extractRawText({ arrayBuffer: reader.result });
      setfileContent(result.value);
      console.log(result.value);
    };
  };

  const handleImageUpload = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
     // const result = await Tesseract.recognize(reader.result, 'eng');
      //setfileContent(result.text);
    };
  };

  const onDrop = (acceptedFiles) => {
    setUploadedFiles(acceptedFiles);
    const file = acceptedFiles[0];
    if (file.type === 'application/pdf') {
      handlePdfUpload(file);
    } else if (
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      file.type === 'application/msword'
    ) {
      handleDocUpload(file);
    } else if (file.type.startsWith('image/')) {
      handleImageUpload(file);
    }
  };

  
  const saveNewJob = async () => {
    const jobName = newJobName.trim();
    const jobDescription = inputText.trim();
    if (jobName && jobDescription) {
      try {
        await store.api.post('/user/JobDetails/save', { jobname: jobName, jobdescription: jobDescription });
        setNewJobName('');
        setInputText('');
        setIsNewJob(false);
        refreshJobList();
        setShowMessage(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleAnalyzeClick = async () => {
    // Check if a file has been uploaded or fileContent is empty
    if (!uploadedFiles.length || !fileContent) {
      return;
    }
    try {

      setIsLoading(true);
      store.inProgress=true;

      const [resumeAnalysisResponse, jobAnalysisResponse] = await Promise.all([
        store.api.post('/ai/analyzeResume', {
          jobid: selectedJobId,
          Resume: fileContent,
          description: inputText,
        }),
        store.api.post('/ai/analyzeJob', {
          jobid: selectedJobId,
          description: inputText,

        }),
      ]);

      const resumeAnalysisResults = resumeAnalysisResponse.data.output;
      const jobAnalysisResults = jobAnalysisResponse.data.output;
      const resumeId=resumeAnalysisResponse.data.resumeId;
      console.log(resumeId);
      console.log(resumeAnalysisResults);
      console.log(jobAnalysisResults);
      ///evaluateCandidate
      store.storeOutput =resumeAnalysisResults;
      store.setJobAnalysis(jobAnalysisResults);
      console.log(store.jobAnalysis);

    const score= await  store.api.post('/ai/evaluateCandidate', {
        jobid: selectedJobId,
        Resume: resumeAnalysisResults,
        ResumeId:resumeId,
        JobDescription: jobAnalysisResults,
      });
      setIsLoading(false);
      store.inProgress=false;
      console.log(score.data.output);
      store.scoreOutput=score.data.output;
    } catch (error) {
      console.error(error);
    }
  };
  const handlePreviewClick = (file) => {
    const fileUrl = URL.createObjectURL(file);
    const previewWindow = window.open('', '_blank', 'width=800,height=600');
    previewWindow.document.write(`<iframe src="${fileUrl}" style="width:100%; height:100%;"></iframe>`);

    // previewWindow.document.write(`<iframe src="https://docs.google.com/gview?url="${fileUrl}"&embedded=true" style="width:100%; height:100%;"></iframe>`);


  };
  const width = window.innerWidth * 0.5; // 80% of the viewport width
const height = window.innerHeight * 0.2; // 20% of the viewport height

return (
  <div className="bg-gray-100 min-h-screen py-8 px-4">
    <div className="container mx-auto">
       <div className="mb-6">
            <Link to="/" className="text-blue-600 hover:text-blue-800 flex items-center">
              <FaChevronLeft className="mr-2" />
              Back to Workflow
            </Link>
          </div> 

        <div className="flex flex-col">
          <label htmlFor="jobId" className="block font-medium text-gray-700 mb-2">
            Job ID
          </label>
          <div className="mb-4 flex flex-row items-start space-x-4 pt-4">
            <div>
              <Dropdown
                id="jobId"
                value={selectedJobId}
                options={store.joblist.map((job) => ({ value: job.jobid, label: job.jobname }))}
                onChange={(e) => {
                  setSelectedJobId(e.value);
                  const jobDescription = store.joblist.find((job) => job.jobid === e.value)?.jobdescription;
                  setInputText(jobDescription || '');
                }}
                placeholder="Select a job ID"
                className="w-72 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div style={{ width: `${width}px`, height: `${height}px` }}>
              <EntryText
                title="Job Description"
                desc="Enter the Job description"
                prompt={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-4 flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
            <div className="mt-4">
              <FileUpload
                uploadedFiles={uploadedFiles}
                onDrop={onDrop}
                handlePreviewClick={handlePreviewClick}
              />
            </div>
          </div>
          <div className="flex items-center mt-4">
                <button
                  onClick={handleAnalyzeClick}
                  disabled={!selectedJobId || isLoading || !uploadedFiles.length}
                  className={`flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-indigo-600 to-blue-600 hover:from-indigo-700 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out ${
                    isLoading || !selectedJobId || !uploadedFiles.length ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isLoading ? (
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : (
                    <LightBulbIcon className="w-5 h-5 mr-2" />
                  )}
                  Analyze Resume
                </button>
              </div>

        </div>
      
    </div>
  </div>
);
};


export default inject('store')(observer(ScreenCandidate));