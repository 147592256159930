import { ThemeProvider } from 'styled-components'
import React, { Component } from 'react';

import { Provider  } from 'mobx-react'
import { observer,  } from 'mobx-react'
import AccountActivation from './Components/Pages/AccountActivation';

import AppStore from './store'
import colors from 'tailwindcss/colors' 
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Header from './Header'
import Search from './Search'
import Pricing from './Pricing' // <--- Add this line

import Screening from './Screening'

import Tool from './Core/Tool'
import Chat from './Core/Chat'

import Login from './Login/Login'
import Layout from './Core/Layout';
import Profile from './Profile/'
import LoginSuccess from './Login/Success'
import CandidateDetails from '../src/Components/CandidateDetails'
import ManageJob from '../src/Components/ManageJob'
import Assesment from '../src/Components/Pages/Assessment'
import WorkFlowGuidePage from '../src/Components/Pages/WorkFlowGuidePage'
import VirtualInterviewWorkflowPage from '../src/Components/Pages/VirtualInterviewWorkflowPage'
import InterviewSchedulingPage from '../src/Components/Pages/Virtual-Interview/InterviewSchedulingPage'
import VirtualInterviewRoomPage from '../src/Components/Pages/VirtualInterviewRoomPage'
import ManageJobPage from '../src/Components/Pages/ManageJobPage'
import '../src/Components/Styles/style.css'


import 'primereact/resources/themes/md-light-indigo/theme.css';
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import Signup from './Login/Signup';

//import '..//node_modules//primereact//resources//themes//bootstrap4-light-blue//theme.css';
//import '..//node_modules//primereact//resources//themes//viva-dark//theme.css'



//import './App.scss'
//import './style.css'

if(!window.store){
  window.store = new AppStore();
}


@observer
class App extends Component {
  render() {
    return (
    <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
           {window.store.redirect ? <Redirect to={window.store.redirect} /> : null }
            {window.store.isLoggedIn ? <>
            {window.store.profile.status ? <>  {/*  Logged in with plan */}
                
               
                <Switch>
                      
                      <Route path="/" exact component={WorkFlowGuidePage} />
                      <Route path="/Screening" exact component={Screening} />

                      <Route path="/candidate-details" exact component={CandidateDetails} />
                      <Route path="/Assesment" exact component={Assesment} />
                      <Route path="/Manage-Jobs" exact component={ManageJobPage} />
                      <Route path="/workflow-guide" exact component={WorkFlowGuidePage} />
                      <Route path="/virtual-interview" exact component={VirtualInterviewWorkflowPage} />
                      <Route path="/virtual-interview/scheduling" exact component={InterviewSchedulingPage} />
                      

                      <Route  path="/virtual-interview-room/:interviewLink"  component={VirtualInterviewRoomPage}  />

                      <Route path="/signup/success" component={WorkFlowGuidePage} />
                      <Route path="/candidate-details" exact component={CandidateDetails} />
                      <Route path="/Assesment" exact component={Assesment} />

                      <Route path="/Manage-Jobs" exact component={ManageJob} />

                      <Route path="/my-profile" component={Profile} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />
                    
                  </Switch>
                  </> : <> {/* Logged in but no plan */}
                 
               
               <Switch>
                      <Route path="/" exact component={WorkFlowGuidePage} />
                      <Route path="/Screening" exact component={Screening} />
                      <Route path="/candidate-details" exact component={CandidateDetails} />
                      <Route path="/Assesment" exact component={Assesment} />

                      <Route path="/Manage-Jobs" exact component={ManageJobPage} />
                      <Route path="/workflow-guide" exact component={WorkFlowGuidePage} />
                      <Route path="/virtual-interview" exact component={VirtualInterviewWorkflowPage} />

                      <Route path="/virtual-interview/scheduling" exact component={InterviewSchedulingPage} />
                      <Route  path="/virtual-interview-room/:interviewLink"  component={VirtualInterviewRoomPage}  />

                  <Route path="/signup/success" component={WorkFlowGuidePage} />
                  <Route path="/my-profile" component={Profile} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />

                  
                  </Switch>
                </>} </> : <> {/*  Not Logged In */}
                <Switch>
                   <Route path="/" exact>
                    <Redirect to="/genai-for-hiring" />
                  </Route> 
                  <Route path="/logout" exact>
                    <Redirect to="/genai-for-hiring" />
                  </Route>
                  <Route path="/genai-for-hiring" component={Layout} />

                  <Route path="/login" component={Login} />
                  <Route path="/signup" component={Login} />
                  <Route path="/auth/account-activation/:token" component={AccountActivation} />



                </Switch>
            </>}
           </Router>
        </Provider>
      </ThemeProvider>
    )
  }
}

export default App