import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import { DocumentTextIcon, DocumentSearchIcon, DocumentReportIcon, UserGroupIcon, VideoCameraIcon, ChartBarIcon } from '@heroicons/react/outline';
import logo from '../Core/Image/DashBoard_logo.png';
import RecruitmentAnimation from './RecruitmentAnimation';
import WorkflowAnimation from './WorkFlowAnimation';

const Header = () => (
  <motion.header
    initial={{ y: -100 }}
    animate={{ y: 0 }}
    transition={{ duration: 0.5 }}
    className="fixed top-0 left-0 right-0 z-50 bg-white shadow-md"
  >
    <div className="container mx-auto px-4 py-2 flex justify-between items-center">
      <img src={logo} alt="Eigentic" className="w-24 h-auto" />
      <nav className="hidden md:flex space-x-6">
        <Link to="features" smooth={true} className="text-sm font-medium text-gray-600 hover:text-blue-600 transition duration-300 cursor-pointer">Features</Link>
        <Link to="how-it-works" smooth={true} className="text-sm font-medium text-gray-600 hover:text-blue-600 transition duration-300 cursor-pointer">How It Works</Link>
        <Link to="testimonials" smooth={true} className="text-sm font-medium text-gray-600 hover:text-blue-600 transition duration-300 cursor-pointer">Testimonials</Link>
        <Link to="contact" smooth={true} className="text-sm font-medium text-gray-600 hover:text-blue-600 transition duration-300 cursor-pointer">Contact</Link>
      </nav>
      <NavLink 
        to="/login" 
        className="bg-blue-600 text-white px-4 py-1 text-lg rounded-full hover:bg-blue-700 transition duration-300"
      >
        Get Started
      </NavLink>
    </div>
  </motion.header>
);

const Hero = () => (
  <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white pt-24 pb-20 overflow-hidden">
    <div className="container mx-auto px-6">
      <div className="flex flex-col md:flex-row items-center">
        <motion.div 
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="md:w-1/2 mb-10 md:mb-0"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 font-sans">
            Revolutionize Your Hiring Process with AI
          </h1>
          <p className="text-xl mb-8 font-sans">
            Eigentic: Your intelligent companion for effortless candidate screening, interviewing, and evaluation.
          </p>
          <NavLink 
            to="/login" 
            className="bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300 inline-block"
          >
            Start Recruiting Smarter
          </NavLink>
        </motion.div>
        <motion.div 
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="md:w-1/2 flex justify-center relative"
        >
          <div className="absolute inset-0 bg-blue-500 opacity-20 rounded-full filter blur-3xl"></div>
          <RecruitmentAnimation />
        </motion.div>
      </div>
    </div>
  </div>
);


const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div 
    whileHover={{ scale: 1.05 }}
    className="bg-white p-6 rounded-lg shadow-lg"
  >
    <Icon className="h-12 w-12 mb-4 text-blue-600"/>
    <h3 className="text-xl font-semibold mb-2 text-gray-800 font-sans">{title}</h3>
    <p className="text-gray-600 font-sans">{description}</p>
  </motion.div>
);

const Features = () => (
  <div id="features" className="py-20 bg-gray-100">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold text-center mb-12 text-gray-800 font-sans">Powerful Features to Streamline Your Recruitment</h2>
      <div className="grid md:grid-cols-3 gap-8">
        <FeatureCard 
          icon={DocumentTextIcon}
          title="Intelligent Resume Parsing"
          description="Extract key information from resumes with our advanced AI technology, saving hours of manual work."
        />
        <FeatureCard 
          icon={DocumentSearchIcon}
          title="Job Description Analysis"
          description="Automatically analyze and break down job descriptions to identify key requirements and skills."
        />
        <FeatureCard 
          icon={DocumentReportIcon}
          title="AI-Powered Resume Scoring"
          description="Objectively evaluate and rank candidates based on job requirements, ensuring the best fit."
        />
        <FeatureCard 
          icon={UserGroupIcon}
          title="Automated Candidate Screening"
          description="Screen thousands of resumes quickly and efficiently, without compromising on quality."
        />
        <FeatureCard 
          icon={VideoCameraIcon}
          title="AI-Driven Video Interviews"
          description="Conduct and analyze technical video interviews at scale, saving time and resources."
        />
        <FeatureCard 
          icon={ChartBarIcon}
          title="Comprehensive Candidate Evaluation"
          description="Get in-depth insights on candidate suitability, technical skills, and cultural fit."
        />
      </div>
    </div>
  </div>
);

const HowItWorks = () => (
  <div id="how-it-works" className="py-20 bg-white">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold text-center mb-12 text-gray-800 font-sans">How Eigentic Works</h2>
      <div className="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12">
        {[
          { step: 1, title: "Upload Resumes", description: "Bulk upload candidate resumes to our secure platform." },
          { step: 2, title: "AI Analysis", description: "Our AI analyzes resumes, job descriptions, and generates insights." },
          { step: 3, title: "Automated Screening", description: "Top candidates are automatically identified and ranked." },
          { step: 4, title: "Conduct Interviews", description: "Use our AI-driven video interview tool for technical assessments." },
          { step: 5, title: "Make Informed Decisions", description: "Leverage comprehensive reports to choose the best candidates." }
        ].map((item, index) => (
          <motion.div 
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="flex flex-col items-center text-center"
          >
            <div className="bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold mb-4">{item.step}</div>
            <h3 className="text-lg font-semibold mb-2 font-sans">{item.title}</h3>
            <p className="text-gray-600 text-sm font-sans">{item.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </div>
);

const Testimonials = () => (
  <div id="testimonials" className="py-20 bg-gray-100">
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold text-center mb-12 text-gray-800 font-sans">What Our Clients Say</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {[
          { name: "Sarah Johnson", role: "HR Manager", company: "Tech Innovators Inc.", quote: "Eigentic has transformed our hiring process. We're now able to find top talent faster than ever before." },
          { name: "Michael Chen", role: "CTO", company: "StartUp Dynamics", quote: "The AI-driven technical interviews have been a game-changer for our engineering team's growth." },
          { name: "Emily Rodriguez", role: "Talent Acquisition Lead", company: "Global Solutions Corp.", quote: "With Eigentic, we've reduced our time-to-hire by 40% while improving the quality of our hires." }
        ].map((testimonial, index) => (
          <motion.div 
            key={index}
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white p-6 rounded-lg shadow-lg"
          >
            <p className="text-gray-600 mb-4 font-sans">"{testimonial.quote}"</p>
            <p className="font-semibold font-sans">{testimonial.name}</p>
            <p className="text-sm text-gray-500 font-sans">{testimonial.role}, {testimonial.company}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </div>
);

const Contact = () => (
  <div id="contact" className="py-20 bg-blue-600 text-white">
    <div className="container mx-auto px-6 text-center">
      <h2 className="text-4xl font-bold mb-8 font-sans">Ready to Transform Your Hiring Process?</h2>
      <p className="text-xl mb-8 font-sans">Get in touch with our team and start recruiting smarter today.</p>
      <a href="mailto:contact@eigentic.com" className="inline-block bg-white text-blue-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300">
        Contact Us
      </a>
    </div>
  </div>
);

const Footer = () => (
  <footer className="bg-gradient-to-r from-blue-800 to-indigo-900 text-white py-8">
    <div className="container mx-auto px-6">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          <p className="text-sm text-gray-300 font-sans">Revolutionizing recruitment with AI</p>
        </div>
        <div className="flex space-x-6">
          <a href="#" className="text-sm text-gray-300 hover:text-white transition duration-300 font-sans">Privacy Policy</a>
          <a href="#" className="text-sm text-gray-300 hover:text-white transition duration-300 font-sans">Terms of Service</a>
          <a href="#" className="text-sm text-gray-300 hover:text-white transition duration-300 font-sans">FAQ</a>
        </div>
      </div>
      <div className="mt-4 text-center text-sm text-gray-300 font-sans">
        &copy; 2024 Eigentic. All rights reserved.
      </div>
    </div>
  </footer>
);

const Layout = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden font-sans">
      <Header />
      <main className="flex-grow pt-14">
        <Hero />
        <WorkflowAnimation />

        <Features />
        <HowItWorks />
        <Testimonials />
        <Contact />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;