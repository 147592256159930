import React, { useState, useEffect, useCallback } from 'react';
import { FaBriefcase, FaUsers, FaCalendarAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import moment from 'moment';
import { observer, inject } from 'mobx-react';

// You may need to adjust the import path for VirtualInterviewRoom
import VirtualInterviewRoom from './VirtualInterviewRoom';

// Basic Select component
const Select = ({ options, onChange, placeholder, isDisabled }) => (
  <select 
    onChange={(e) => onChange(e.target.value)} 
    disabled={isDisabled}
    className="w-full p-2 border rounded"
  >
    <option value="">{placeholder}</option>
    {options.map(option => (
      <option key={option.value} value={option.value}>{option.label}</option>
    ))}
  </select>
);

// Basic Button component
const Button = ({ onClick, children, className, disabled }) => (
  <button 
    onClick={onClick} 
    className={`px-4 py-2 rounded ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    disabled={disabled}
  >
    {children}
  </button>
);

// Basic Modal component
const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        {children}
      </div>
    </div>
  );
};

const InterviewScheduling = ({ store }) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [showInterviewRoom, setShowInterviewRoom] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);

  const fetchCandidates = useCallback(async (jobId) => {
    try {
      const response = await store.api.post('/user/CandidatesByJobId/find', { "jobid": jobId });
      setCandidates(response.data);
    } catch (error) {
      console.error("Failed to fetch candidates:", error);
    }
  }, [store.api]);

  const refreshJobList = useCallback(async () => {
    try {
      setIsLoading(true);
      let response = await store.api.post('/user/JobDetails/view');
      store.joblist = response.data.map((job) => ({
        jobid: job.jobId,
        jobname: job.jobname,
        jobdescription: job.jobdescription,
      }));
    } catch (error) {
      console.error("Failed to fetch job list:", error);
    } finally {
      setIsLoading(false);
    }
  }, [store]);

  useEffect(() => {
    refreshJobList();
  }, [refreshJobList]);

  const handleJobSelect = (jobId) => {
    const job = store.joblist.find(job => job.jobid === parseInt(jobId));
    setSelectedJob(job);
    if (job) {
      fetchCandidates(job.jobid);
    }
    setSelectedCandidate(null);
    setSelectedDate('');
    setSelectedTime('');
  };

  const handleCandidateSelect = (candidateId) => {
    setSelectedCandidate(candidates.find(candidate => candidate.resumeId === candidateId));
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const handleScheduleClick = () => {
    if (selectedJob && selectedCandidate && selectedDate && selectedTime) {
      setIsConfirmationOpen(true);
    } else {
      alert("Please select all required fields before scheduling.");
    }
  };

  const handleConfirmSchedule = async () => {
    if (selectedJob && selectedCandidate && selectedDate && selectedTime) {
      try {
        const response = await store.api.post('/user/InterviewDetails/save', {
          "jobId": selectedJob.jobid,
          "candidateName": selectedCandidate.name,
          "candidateEmail": selectedCandidate.email,
          "jobDescription": selectedJob.jobdescription,
          "jobName": selectedJob.jobname,
          "interviewDate": selectedDate,
          "interviewTime": selectedTime
        });

        if (response.data.success) {
          setIsConfirmationOpen(false);
          // Reset selections or show a success message
          setSelectedJob(null);
          setSelectedCandidate(null);
          setSelectedDate('');
          setSelectedTime('');
          alert("Interview scheduled successfully and invitation sent to the candidate!");
        } else {
          alert("Failed to schedule interview. Please try again.");
        }
      } catch (error) {
        console.error("Error scheduling interview:", error);
        alert("An error occurred while scheduling the interview. Please try again.");
      }
    } else {
      alert("Please select all required fields before scheduling.");
    }
  };

  const handleClearSelection = () => {
    setSelectedJob(null);
    setSelectedCandidate(null);
    setSelectedDate('');
    setSelectedTime('');
  };

  const handleEnterInterviewRoom = () => {
    setShowInterviewRoom(true);
  };

  const handleExitInterviewRoom = () => {
    setShowInterviewRoom(false);
  };

  // Generate date options for the next 14 days
  const dateOptions = [...Array(14)].map((_, i) => {
    const date = moment().add(i, 'days');
    return { 
      value: date.format('YYYY-MM-DD'), 
      label: date.format('dddd, MMMM D, YYYY')
    };
  });

  // Generate time options from 9 AM to 5 PM in 30-minute intervals
  const timeOptions = [...Array(17)].map((_, i) => {
    const time = moment().set({ hour: 9, minute: 0 }).add(i * 30, 'minutes');
    return { 
      value: time.format('HH:mm'), 
      label: time.format('h:mm A')
    };
  });

  if (showInterviewRoom) {
    return (
      <VirtualInterviewRoom 
        isRecruiter={true}
        candidateName={selectedCandidate?.name || 'Candidate'}
        jobTitle={selectedJob?.jobname || 'Job'}
        onExit={handleExitInterviewRoom}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">Schedule Interview</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {/* Job Selector */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaBriefcase className="mr-2 text-blue-500" /> Select Job
          </h2>
          <Select
            options={store.joblist.map(job => ({ value: job.jobid, label: job.jobname }))}
            onChange={handleJobSelect}
            placeholder="Choose a job position"
            isDisabled={isLoading}
          />
        </div>

        {/* Candidate List */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaUsers className="mr-2 text-green-500" /> Select Candidate
          </h2>
          <Select
            options={candidates.map(candidate => ({ 
              value: candidate.resumeId, 
              label: `${candidate.name} (${candidate.email})` 
            }))}
            onChange={handleCandidateSelect}
            placeholder="Choose a candidate"
            isDisabled={!selectedJob}
          />
        </div>

        {/* Date Selector */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaCalendarAlt className="mr-2 text-orange-500" /> Select Date
          </h2>
          <Select
            options={dateOptions}
            onChange={handleDateSelect}
            placeholder="Choose a date"
            isDisabled={!selectedCandidate}
          />
        </div>

        {/* Time Selector */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4 flex items-center">
            <FaClock className="mr-2 text-purple-500" /> Select Time
          </h2>
          <Select
            options={timeOptions}
            onChange={handleTimeSelect}
            placeholder="Choose a time"
            isDisabled={!selectedDate}
          />
        </div>
      </div>

      {/* Interview Details and Actions */}
      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <FaCalendarAlt className="mr-2 text-indigo-500" /> Interview Details
        </h2>
        <div className="space-y-2 mb-4">
          <p><strong>Job:</strong> {selectedJob?.jobname || 'Not selected'}</p>
          <p><strong>Candidate:</strong> {selectedCandidate?.name || 'Not selected'}</p>
          <p><strong>Date:</strong> {selectedDate ? moment(selectedDate).format('dddd, MMMM D, YYYY') : 'Not selected'}</p>
          <p><strong>Time:</strong> {selectedTime ? moment(selectedTime, 'HH:mm').format('h:mm A') : 'Not selected'}</p>
        </div>
        <div className="flex justify-end space-x-4">
          <Button onClick={handleClearSelection} className="bg-red-500 text-white hover:bg-red-600">
            Clear Selection
          </Button>
          <Button 
            onClick={handleScheduleClick} 
            className="bg-green-500 text-white hover:bg-green-600"
            disabled={!(selectedJob && selectedCandidate && selectedDate && selectedTime)}
          >
            Schedule Interview
          </Button>
        </div>
      </div>

      {/* Confirmation Dialog */}
      <Modal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title="Confirm Interview Schedule"
      >
        <div className="p-6">
          <h3 className="text-lg font-semibold mb-4">Please confirm the interview details:</h3>
          <p><strong>Job:</strong> {selectedJob?.jobname}</p>
          <p><strong>Candidate:</strong> {selectedCandidate?.name}</p>
          <p><strong>Date:</strong> {selectedDate ? moment(selectedDate).format('dddd, MMMM D, YYYY') : ''}</p>
          <p><strong>Time:</strong> {selectedTime ? moment(selectedTime, 'HH:mm').format('h:mm A') : ''}</p>
          <div className="mt-6 flex justify-end space-x-4">
            <Button onClick={() => setIsConfirmationOpen(false)} className="bg-gray-300 hover:bg-gray-400">Cancel</Button>
            <Button onClick={handleConfirmSchedule} className="bg-green-500 hover:bg-green-600 text-white">
              <FaEnvelope className="mr-2 inline" /> Send Invite
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default inject('store')(observer(InterviewScheduling));