import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import SingleSelect from '../Components/EntryDropdown';

const JobList = ({ store, onChange }) => {
 
  const joblistOptions = [
    {
      value: '', // Unique identifier for "Create New"
      label: 'Create New',
      Icon: 'title', // Display text for "Create New"
    },
    ...store.joblist.map((job) => ({
      value: job.jobid,
      label: job.jobname,
      Icon: 'title',
    })),
  ];

  return (
    <SingleSelect options={joblistOptions} onChange={onChange} />
  );
};

export default inject('store')(observer(JobList));