import jobad from './jobad';
import summarize from './summarize';
import codeInterpret from './interpret';
import intro from './intro';
import article from './article';
import reviewer from './reviewer';
import coverletter from './coverletter';
import resume from './resume';

const TOOLS = [

	codeInterpret,
	intro,
	jobad,
	summarize,
	article,
	reviewer,
	coverletter,
	resume,
]

export default TOOLS
