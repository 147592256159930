import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt, FaVideo, FaUserCircle, FaChartLine } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';
import VirtualInterviewRoom from './VirtualInterviewRoom';
import CompletedInterviews from './CompletedInterviews';

const InterviewCard = ({ interview, onJoin }) => (
  <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-4 hover:shadow-md transition-shadow duration-300">
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-base font-medium text-gray-800">{interview.jobTitle}</h3>
      <span className="px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
        Scheduled
      </span>
    </div>
    <p className="text-sm text-gray-600 mb-2">Candidate: {interview.candidateName}</p>
    <p className="text-sm text-gray-600 mb-3">{interview.date} at {interview.time}</p>
    {/* <button
      onClick={() => onJoin(interview.id)}
      className="w-full bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-3 rounded-md transition-colors duration-300"
    >
      <FaVideo className="inline-block mr-2" />
      Join Interview
    </button> */}
  </div>
);

const VirtualInterviewWorkflow = inject('store')(observer(({ store }) => {
  const [showInterviewRoom, setShowInterviewRoom] = useState(false);
  const [currentInterview, setCurrentInterview] = useState(null);
  const [upcomingInterviews, setUpcomingInterviews] = useState([]);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUpcomingInterviews();
  }, []);

  const fetchUpcomingInterviews = async () => {
    setIsLoading(true);
    try {
      //const response = await fetch('/user/interviews/upcoming', {

      const response = await store.api.get('/user/interviews/upcoming');

      if (response.data && Array.isArray(response.data)) {
        setUpcomingInterviews(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching upcoming interviews:', error);
      // You might want to set an error state here and display it to the user
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoinInterview = (interviewId) => {
    const interview = upcomingInterviews.find(i => i.id === interviewId);
    setCurrentInterview(interview);
    setShowInterviewRoom(true);
  };

  const handleExitInterviewRoom = () => {
    setShowInterviewRoom(false);
    setCurrentInterview(null);
    // Refresh upcoming interviews after completing one
    fetchUpcomingInterviews();
  };

  if (showInterviewRoom && currentInterview) {
    return (
      <VirtualInterviewRoom 
        interviewDetails={currentInterview}
        onExit={handleExitInterviewRoom}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 py-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-6">Virtual Interview Dashboard</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="flex border-b mb-4">
              <button
                className={`py-2 px-4 font-medium ${activeTab === 'upcoming' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
                onClick={() => setActiveTab('upcoming')}
              >
                Upcoming Interviews
              </button>
              <button
                className={`py-2 px-4 font-medium ${activeTab === 'completed' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
                onClick={() => setActiveTab('completed')}
              >
                Completed Interviews
              </button>
            </div>
            
            {activeTab === 'upcoming' ? (
              <div>
                {isLoading ? (
                  <p>Loading upcoming interviews...</p>
                ) : upcomingInterviews.length > 0 ? (
                  upcomingInterviews.map(interview => (
                    <InterviewCard 
                      key={interview.id} 
                      interview={interview} 
                      onJoin={handleJoinInterview}
                    />
                  ))
                ) : (
                  <p>No upcoming interviews scheduled.</p>
                )}
              </div>
            ) : (
              <CompletedInterviews />
            )}
          </div>
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <h2 className="text-lg font-medium text-gray-800 mb-4">Quick Actions</h2>
            <Link to="/virtual-interview/scheduling" className="block bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-3 px-4 rounded-md mb-3 text-center transition-colors duration-300">
              <FaCalendarAlt className="inline-block mr-2" />
              Schedule New Interview
            </Link>
            <Link to="/interview-analytics" className="block bg-green-500 hover:bg-green-600 text-white text-sm font-medium py-3 px-4 rounded-md text-center transition-colors duration-300">
              <FaChartLine className="inline-block mr-2" />
              View Analytics
            </Link>
          </div>

          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <h2 className="text-lg font-medium text-gray-800 mb-4">Interview Tips</h2>
            <ul className="text-sm text-gray-600 space-y-2">
              <li className="flex items-start">
                <FaUserCircle className="text-blue-500 mt-1 mr-2" />
                Ensure a quiet, well-lit environment
              </li>
              <li className="flex items-start">
                <FaUserCircle className="text-blue-500 mt-1 mr-2" />
                Test your camera and microphone beforehand
              </li>
              <li className="flex items-start">
                <FaUserCircle className="text-blue-500 mt-1 mr-2" />
                Have a stable internet connection
              </li>
              <li className="flex items-start">
                <FaUserCircle className="text-blue-500 mt-1 mr-2" />
                Dress professionally and maintain good posture
              </li>
              <li className="flex items-start">
                <FaUserCircle className="text-blue-500 mt-1 mr-2" />
                Be prepared to discuss your experience and skills
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}));

export default VirtualInterviewWorkflow;