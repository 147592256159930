import {React,Component} from 'react';
import { NavLink } from 'react-router-dom';
import { FaUserCircle, FaPowerOff } from 'react-icons/fa';
import { BsBoxArrowRight, BsPerson } from 'react-icons/bs';
import { RiMoneyDollarBoxLine } from 'react-icons/ri';
import { FaTh, FaTools, FaFileAlt, FaEdit } from 'react-icons/fa';
import { observer, inject } from 'mobx-react'




import './Layout.css'
@inject('store')
@observer

class Header extends Component {
	render() {
    return (

      <>
      <header className="bg-white shadow-md">
    <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
      {/* Logo */}
      <div className="flex items-center">
          <NavLink to="/" className="text-2xl font-bold text-gray-900">
            EIGEN<span className="text-blue-500">TIC</span>
          </NavLink>
        </div>     
      {/* User profile and logout */}
      <div className="flex items-center space-x-4">
        
        {/* Upgrade button */}
        <button  className="bg-blue-500 hover:bg-green-600 text-white font-semibold py-1 px-4 rounded-full">
          Upgrade
        </button>
        
        {/* User profile */}
        <div className="relative">
          <NavLink to="/my-profile">
            <FaUserCircle className="text-blue-900 h-6 w-6" />
          </NavLink>
        </div>
        
        {/* Logout */}
        <div className="relative">
          <NavLink to="/logout" onClick={this.props.store.handleLogout}>
            <FaPowerOff className="text-red-900 h-6 w-6" />
          </NavLink>
        </div>
      </div>
    </nav>
  </header>
      </>
      
      )
  }
}

  const Footer = () => (
    <footer className="bg-white shadow-md">
      <div className="container mx-auto px-6 py-3">
        <p className="text-black-200 text-center">&copy; 2023 Eigentic. All rights reserved.</p>
      </div>
    </footer>
  );
  
  @inject('store')
@observer
  class Sidebar extends Component {
    render() {
      return (
  
        <>
        <div className="bg-gray-900 h-screen w-1/6 pt-8 pb-16">
                    <nav>
                <NavLink to="/" activeClassName="bg-gray-700" className="block px-4 py-2.5 text-white flex items-center rounded hover:bg-gray-700">
                    <FaTh className="mr-3" /> Screening
                </NavLink>
                <NavLink to="/" activeClassName="bg-gray-700" className="block px-4 py-2.5 text-white flex items-center rounded hover:bg-gray-700">
                    <FaTools className="mr-3" /> Candidate List
                </NavLink>
                <NavLink to="/my-profile/history" activeClassName="bg-gray-700" className="block px-4 py-2.5 text-white flex items-center rounded hover:bg-gray-700">
                    <FaFileAlt className="mr-3" /> Job Management
                </NavLink>
                <NavLink to="/Assesment" activeClassName="bg-gray-700" className="block px-4 py-2.5 text-white flex items-center rounded hover:bg-gray-700">
                    <FaEdit className="mr-3" /> Candidate Assesment
                </NavLink>
            </nav>
            <div className="flex justify-center items-center absolute bottom-0 pb-4">
                <div className="mr-4">
                    <RiMoneyDollarBoxLine className="text-white text-3xl" />
                </div>
                <div>
                    <p className="text-gray-200 text-sm">Credit remaining</p>
                    <p className="text-white text-lg font-bold">{this.props.store.profile.credits}/1000</p>
                </div>
            </div>


      </div>
        </>
      )
    }
  }

  
const DashboardLayout = ({ children }) => (
  <div>
    <Header />
    <div className="dashboard-container">

        <Sidebar />
       

     <main className="dashboard-main"> 
    
        {children}
   
  </main> 
    
  </div>
  {/* //<Footer/> */}
  </div>
);

export default DashboardLayout;
