import React from 'react';
import { Component } from 'react';

import gapi from 'gapi-script'
import { observable, makeObservable, } from 'mobx'

import { observer, inject } from 'mobx-react'

import GoogleLogin from 'react-google-login';
import axios from 'axios';
require('dotenv').config();

// window.gapi.load('client:auth2', () => {
//     window.gapi.client.init({
//         clientId:`669054519660-9annunp46n7q5cqnk7k0l9366l7arr9e.apps.googleusercontent.com`,
//         plugin_name: "chat"
//     })})

@inject('store')
@observer
 class Google extends Component  {

   @observable email = ""
	@observable idToken = ""
	@observable fname = ""
	@observable lname = ""
	@observable errorMessage = ""


  @observable  googleClientId=`669054519660-9annunp46n7q5cqnk7k0l9366l7arr9e.apps.googleusercontent.com`
  constructor(){
    super()
    makeObservable(this)
}

responseGoogle = async (response) => {
    try {
       // e.preventDefault()
        this.errorMessage = ""
        console.log(response.tokenId)
        let data = await this.props.store.api.post('/auth/google-login', {
                   idToken: response.tokenId,
                    
                }).then(({data}) => data)
                console.log(`onSignup`,data)
        if(data.token && data.profile){
            this.props.store.loginWithDataTokenAndProfile(data)
        }
    } catch (err){
        console.log(err)
        console.log(err?.response?.data?.message)
        if(err?.response?.data?.message){
            this.errorMessage = err?.response?.data?.message
        }
    }
}


         


        render() {
    return (
        <div className="flex flex-col flex-1">
            <GoogleLogin
                clientId={this.googleClientId}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
               
                cookiePolicy={'single_host_origin'}
            />
        </div>
    );
 }
        

        }

export default Google;
