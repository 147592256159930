import React from 'react';
import Layout from '../Layout/Layout';
import VirtualInterviewWorkflow from './Virtual-Interview/VirtualInterviewWorkflow';

const VirtualInterviewWorkflowPage = () => {
  return (
    <Layout>
      <VirtualInterviewWorkflow />
    </Layout>
  );
};

export default VirtualInterviewWorkflowPage;