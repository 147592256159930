import React from 'react';
import Layout from '../Layout/Layout';
import ManageJob from './ManageJob';

const ManageJobPage = () => {
  return (
    <Layout>
      <ManageJob />
    </Layout>
  );
};

export default ManageJobPage;