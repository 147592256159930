import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@inject('store')
@observer
class Search extends Component {
  @computed get permissions() {
    return this.props.store.tools.filter((tool) =>
      tool.permissions.some((r) => this.props.store.profile.permissions.includes(r))
    );
  }

  render() {
    return (
      <div className="relative">
        <div className="flex items-center justify-end w-full mx-auto mb-4">
          <div className="relative w-1/2">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
            </span>
            <input
              type="search"
              id="q"
              name="q"
              className="py-2 pl-10 pr-2 md:pl-14 md:pr-10 text-lg focus:outline-none focus:text-gray-900 transition flex flex-1 w-full rounded-full bg-gray-100"
              style={{ width: '100%' }}
              placeholder="Search tools..."
              autoComplete="off"
              value={this.props.store.toolsKeyword}
              onChange={this.props.store.onChangeToolsKeyword}
              onKeyUp={this.onKeyUp}
            />
          </div>
        </div>

        
      </div>
    );
  }
}

export default Search;
