import React, { useState, useEffect } from 'react';
import { NavLink,useHistory } from 'react-router-dom';
import { FaRegUserCircle, FaSignOutAlt, FaRocket, FaBell } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';

const Header = inject('store')(observer(({ store }) => {
  const [showNotification, setShowNotification] = useState(false);
  const history = useHistory(); 


  const handleLogout = () => {
    store.handleLogout();
    history.push('/login'); 

  };

  const handleNotificationClick = () => {
    setShowNotification(true);
    
    // Auto-close the notification after 3 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  // Close the notification when clicking outside
  useEffect(() => {
    const closeNotification = (e) => {
      if (showNotification && !e.target.closest('.notification-dropdown')) {
        setShowNotification(false);
      }
    };

    document.addEventListener('click', closeNotification);
    return () => document.removeEventListener('click', closeNotification);
  }, [showNotification]);

  return (
    <header className="bg-gradient-to-r from-blue-600 to-indigo-800 shadow-md">
      <nav className="container mx-auto px-4 h-16 flex items-center justify-end">
        <div className="flex items-center space-x-4">
          <div className="text-blue-100 text-sm font-medium mr-4">
            Welcome, {store.profile.fname}
          </div>
          
          <div className="relative">
            <button 
              onClick={handleNotificationClick}
              className="text-blue-100 hover:text-white transition duration-300"
            >
              <FaBell className="h-5 w-5" />
            </button>
            {showNotification && (
              <div className="notification-dropdown absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                <div className="px-4 py-2 text-sm text-gray-700">
                  No new messages
                </div>
              </div>
            )}
          </div>
          
          <NavLink to="/my-profile" className="bg-white text-indigo-800 text-sm font-semibold py-1.5 px-3 rounded-full flex items-center transition duration-300 hover:bg-blue-100">
            <FaRocket className="text-indigo-800 h-3.5 w-3.5 mr-1.5" />
            Upgrade
          </NavLink>
          
          <div className="flex items-center space-x-3">
            <NavLink to="/my-profile" className="text-blue-100 hover:text-white transition duration-300">
              <FaRegUserCircle className="h-5 w-5" />
            </NavLink>
            
            <button onClick={handleLogout} className="text-blue-100 hover:text-white transition duration-300">
              <FaSignOutAlt className="h-5 w-5" />
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}));

export default Header;