import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Resume Review",
	desc: "Review resume and provide feedback",
	category: "Content",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "gray-500",
	toColor: "gray-500",

	to: "/ai/reviewer",
	api: "/ai/reviewer",

	output: {
		title: "Resume review",
		desc: "The following key points detected",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Entry Text",
		desc: "Review resume and provide feedback.",
		// n: 1,
		prompts: [{ 
				title: "Content", 
				attr: "content",  
				value: "", 
				placeholder: "Paste resume content here ...", 
				label: "",
				type: "textarea",
				maxLength: 3000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "3 + years of experience in AI /Machine learning and Azure.Overall 11+ years of software development (Microsoft .NET) and AI experience.Hands on experience in Microsoft Bot Framework, cognitive services, Machine learning and deep learning.Experience in building machine learning and deep learning model.Implemented various innovative automation ideas across Business unit and projects.Experience in executing automation idea using Machine learning, Chatbot, Azure cognitive services, SPLUNK.Implemented MLOPS CI/CD pipeline using Azure.Expert in fixing/ troubleshooting production issues in Azure/.NET platform.Understanding the technical requirements of existing .NET application/Database and migration to Microsoft Azure cloud platform.Responsible for training, building, mentoring, and managing high performing teams (10+ members) spread across multiple locations.",
			},
		],
		example: {
			output: "Feedback:For making your resume more readable you should add appropriate sections such as a summary section at the top of your resume that outlines your experience and skills in a few sentences or bullet points. You should also add sections such as Education/Qualifications and Technical Skills to make it easier for recruiters to quickly scan through your resume. To make it easier to scan you can use bold font or italics for headings or important words/phrases in each section so they stand out more clearly. You can also include numbers and results from projects you have worked on such as how many members were part of teams you managed or how much time was saved by automation ideas implemented by you etc.. Finally when choosing action verbs try using strong verbs instead of buzzwords like “implemented” instead of “utilized” or “managed” instead of “oversaw” etc",
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

