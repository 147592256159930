import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import Google from './Google';


const Logon = observer(({ active, email, password, onChange, onLogin, landingPageUrl, signUp }) => {

	

	return (
	  <>
<form onSubmit={onLogin}>
              		
               	 <div className="mt-3 text-center ">
                   
					
					<div class="border border-solid">
					   <Google  />

					</div>
	
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email Address</label>
						<input value={email} onChange={e=>onChange(e.target.value,'email')} focus="true" type="email" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>
					<div className="flex flex-col flex-1">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
						<input value={password} onChange={e=>onChange(e.target.value,'password')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" placeholder="*******" />
					</div>
									<div className="flex flex-col">
						<button type="submit" className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
							Log in
						</button>
						{/* <div onClick={signUp} className="hover:bg-gray-600 font-medium rounded-lg text-lg px-4 py-2 bg-gray-500 text-white mt-4 border border-gray-300 inline-block" >
						signUp Google
						</div>
	*/ }
					</div>
                    </div>
                  </form>
	  </>
	)
  })


export default Logon;