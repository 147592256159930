import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useTable, usePagination, useSortBy, useGlobalFilter } from 'react-table';
import { 
  Box, 
  Button, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  IconButton,
  Toolbar,
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import { 
  Add as AddIcon, 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon
} from '@mui/icons-material';

const ManageJob = ({ store }) => {
  const [jobs, setJobs] = useState([]);
  const [jobName, setJobName] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = async () => {
    try {
      const response = await store.api.post('/user/JobDetails/view');
      setJobs(response.data);
    } catch (error) {
      console.error(error);
      showSnackbar('Failed to fetch jobs', 'error');
    }
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  const saveJob = async () => {
    if (jobName.trim() && jobDescription.trim()) {
      setIsLoading(true);
      try {
        const response = await store.api.post('/JobDetails/save', {
          jobname: jobName,
          jobdescription: jobDescription
        });
  
        if (response.data.success) {
          showSnackbar(response.data.message);
          setJobName('');
          setJobDescription('');
          setIsAddDialogOpen(false);
          getJobs(); // Refresh the job list
        } else {
          throw new Error(response.data.message || 'Failed to create job');
        }
      } catch (error) {
        console.error('Error saving job:', error);
        showSnackbar(error.response?.data?.message || error.message || 'Failed to create job', 'error');
      } finally {
        setIsLoading(false);
      }
    } else {
      showSnackbar('Please fill in both job name and description', 'warning');
    }
  };
  
  const updateJob = async () => {
    if (selectedJob && jobName.trim() && jobDescription.trim()) {
      try {
        await store.api.post('/user/JobDetails/update', { jobid: selectedJob.jobId, jobname: jobName, jobdescription: jobDescription });
        showSnackbar('Job Updated Successfully');
        setJobName('');
        setJobDescription('');
        setIsEditDialogOpen(false);
        getJobs();
      } catch (error) {
        console.error(error);
        showSnackbar('Failed to update job', 'error');
      }
    }
  };

  const deleteJob = async () => {
    if (selectedJob) {
      try {
        await store.api.post('/user/JobDetails/delete', { jobid: selectedJob.jobId });
        showSnackbar('Job Deleted Successfully');
        setSelectedJob(null);
        setIsDeleteDialogOpen(false);
        getJobs();
      } catch (error) {
        console.error(error);
        showSnackbar('Failed to delete job', 'error');
      }
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Job ID', accessor: 'jobId' },
      { Header: 'Job Name', accessor: 'jobname' },
      { Header: 'Job Description', accessor: 'jobdescription' },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <Box>
            <Tooltip title="Edit">
              <IconButton onClick={() => handleEditJob(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => handleDeleteJob(row.original)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    { columns, data: jobs, initialState: { pageIndex: 0, pageSize: 10 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleEditJob = (job) => {
    setSelectedJob(job);
    setJobName(job.jobname);
    setJobDescription(job.jobdescription);
    setIsEditDialogOpen(true);
  };

  const handleDeleteJob = (job) => {
    setSelectedJob(job);
    setIsDeleteDialogOpen(true);
  };

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', py: 3 }}>
      <Box maxWidth="lg" mx="auto">
        <Button 
          component={Link} 
          to="/" 
          startIcon={<ArrowBackIcon />} 
          sx={{ mb: 2 }}
        >
          Back to Workflow
        </Button>

        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h4" gutterBottom>
            Manage Jobs
          </Typography>

          <Toolbar>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => setIsAddDialogOpen(true)}
              sx={{ mr: 2 }}
            >
              Add New Job
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <TextField
              variant="outlined"
              placeholder="Search..."
              size="small"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
              }}
              onChange={(e) => setGlobalFilter(e.target.value)}
              value={globalFilter || ''}
            />
          </Toolbar>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table {...getTableProps()}>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <TableCell
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        sx={{ fontWeight: 'bold' }}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <TableRow {...row.getRowProps()} key={row.id}>
                          {row.cells.map(cell => {
                            // Extract the key from props
                            const { key, ...cellProps } = cell.getCellProps();
                            return <TableCell key={key} {...cellProps}>{cell.render('Cell')}</TableCell>
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                              </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </Button>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </Button>
            <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </Button>
            <Typography component="span" sx={{ mx: 2 }}>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </Typography>
            <Typography component="span">
              | Go to page:{' '}
              <TextField
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                size="small"
                sx={{ width: 70 }}
              />
            </Typography>{' '}
            <TextField
              select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
              size="small"
              sx={{ ml: 2, width: 120 }}
              SelectProps={{ native: true }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </TextField>
          </Box>
        </Paper>

        {/* Add Job Dialog */}
        <Dialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)}>
          <DialogTitle>Add New Job</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Job Name"
              fullWidth
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Job Description"
              fullWidth
              multiline
              rows={4}
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>
            <Button onClick={saveJob} variant="contained" color="primary">Save</Button>
          </DialogActions>
        </Dialog>

        {/* Edit Job Dialog */}
        <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
          <DialogTitle>Edit Job</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Job Name"
              fullWidth
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Job Description"
              fullWidth
              multiline
              rows={4}
              value={jobDescription}
              onChange={(e) => setJobDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
            <Button onClick={updateJob} variant="contained" color="primary">Update</Button>
          </DialogActions>
        </Dialog>

        {/* Delete Job Dialog */}
        <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the job "{selectedJob?.jobname}"?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
            <Button onClick={deleteJob} variant="contained" color="error">Delete</Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default inject('store')(observer(ManageJob));