import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { observable, makeObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Helmet } from "react-helmet";

@inject('store')
@observer
class AccountActivation extends Component {
  @observable activationStatus = 'loading';
  @observable errorMessage = '';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.activateAccount();
  }

  activateAccount = async () => {
    const { token } = this.props.match.params;
    try {
      await this.props.store.api.post('/auth/account-activation', { token });
      this.activationStatus = 'success';
    } catch (err) {
      console.error('Activation error:', err.response?.data?.error || err.message);
      this.activationStatus = 'error';
      this.errorMessage = err.response?.data?.message || 'An error occurred during account activation.';
    }
  }

  handleRedirect = () => {
    this.props.history.push('/genai-for-hiring');
  }

  render() {
    return (
      <div className="container mx-auto lg:px-4 py-4 min-h-screen flex flex-col md:items-center md:justify-center">
        <Helmet>
          <title>Account Activation - Eigentic</title>
        </Helmet>
        <div className="min-w-full md:min-w-0 bg-white rounded-xl shadow-xl transform transition-all transition shadow-md hover:shadow-2xl focus:shadow-2xl w-1/2">
          <div className="px-4 py-4 md:px-4 md:py-4">
            <h2 className="text-2xl font-bold mb-4">Account Activation</h2>
            {this.activationStatus === 'loading' && <p>Activating your account...</p>}
            {this.activationStatus === 'success' && (
              <div>
                <p className="text-green-600">Your account has been successfully activated!</p>
                <button 
                  onClick={this.handleRedirect}
                  className="mt-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                >
                  Go to Login
                </button>
              </div>
            )}
            {this.activationStatus === 'error' && (
              <div>
                <p className="text-red-600">There was an error activating your account. The link may have expired or is invalid.</p>
                <button 
                  onClick={() => this.props.history.push('/signup')}
                  className="mt-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                >
                  Sign Up Again
                </button>
              </div>
            )}
            {this.errorMessage && (
              <div className="text-red-600 bg-red-50 rounded-md p-1 text-center mt-4">
                {this.errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountActivation);