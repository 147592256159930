import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Resume Coverletter",
	desc: "Coverletter for Resume",
	category: "Content",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "gray-500",
	toColor: "gray-500",

	to: "/ai/coverletter",
	api: "/ai/coverletter",

	output: {
		title: "Resume Cover Letter",
		desc: "The following key points detected",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Entry Text",
		desc: "Generates Resume Cover Letter",
		// n: 1,
		prompts: [{ 
				title: "Content", 
				attr: "content",  
				value: "", 
				placeholder: "Paste resume content here ...", 
				label: "",
				type: "textarea",
				maxLength: 3000,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "3 + years of experience in AI /Machine learning and Azure.Overall 11+ years of software development (Microsoft .NET) and AI experience.Hands on experience in Microsoft Bot Framework, cognitive services, Machine learning and deep learning.Experience in building machine learning and deep learning model.Implemented various innovative automation ideas across Business unit and projects.Experience in executing automation idea using Machine learning, Chatbot, Azure cognitive services, SPLUNK.Implemented MLOPS CI/CD pipeline using Azure.Expert in fixing/ troubleshooting production issues in Azure/.NET platform.Understanding the technical requirements of existing .NET application/Database and migration to Microsoft Azure cloud platform.Responsible for training, building, mentoring, and managing high performing teams (10+ members) spread across multiple locations.",
			},
		],
		example: {
			output: 'Dear Hiring Manager,\n'+
			'\n' +
			'I am writing to express my interest in the position of AI/Machine Learning and Azure specialist. With over 11 years of software development experience and 3+ years of experience in AI/Machine Learning and Azure, I am confident that I have the skills necessary to be a successful candidate for this role. \n'+
			'\n'+
			'My hands-on experience with Microsoft Bot Framework, Cognitive Services, Machine Learning and Deep Learning has enabled me to develop machine learning models that are both innovative and effective. Additionally, I have implemented various automation ideas across business units and projects using Machine Learning, Chatbot, Azure Cognitive Services, SPLUNK. My expertise also includes setting up MLOPS CI/CD pipelines using Azure as well as troubleshooting production issues on the .NET platform. \n'+
			'\n'+
			'Furthermore, my past experiences have allowed me to gain an understanding of technical requirements for existing .NET applications/databases which can be used during migration processes into Microsoft Azure Cloud Platforms. Lastly, I have been responsible for training teams (10+ members) spread across multiple locations while managing their performance effectively."',
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

