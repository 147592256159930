const dev = {
	// baseURL: '/api/',
	// landingPageUrl: "https://ailmechat.azurewebsites.net/",
	baseURL: "http://localhost:8080/api/",
	landingPageUrl: "http://localhost:8080/",
	stripe: {
		free: "price_1MVVAJSBHAggPpH8HjQzh436",
		entry: "price_1MVVCBSBHAggPpH8pFaRU37n",
		pro: "price_1MVVCeSBHAggPpH8pmM7edl1"
	},
};
  
const prod = {
	baseURL: "https://eigentic.com/api/",
	landingPageUrl: "https://eigentic.com/",
	stripe: {
		free: "price_1MVVAJSBHAggPpH8HjQzh436",
		entry: "price_1MVVCBSBHAggPpH8pFaRU37n",
		pro: "price_1MVVCeSBHAggPpH8pmM7edl1"
	},
};
  
const config = process.env.REACT_APP_NODE_ENV === 'development'
	? dev
	: prod;
  
export default config;