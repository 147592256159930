import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import {
	CheckIcon,
	UserIcon,
	LockClosedIcon ,
	} from '@heroicons/react/outline'
const Signup = observer(({ active, email, password, fname, lname, onChange, onSignup,isSigningUp }) => {
	return (
	  <>
	  {/* onSignup */}
<form onSubmit={onSignup}>
              		{/* <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-${email && password ? "green" : "gray"}-300  ${email && password ? "bg-green-300" : "bg-gray-300"} `}>
			  		<UserIcon className={`h-8 w-8 ${active ? "text-green-700" : "text-gray-500"} text-${email && password ? "green-700" : "gray-500"}`} aria-hidden="true" />
                </div> */}
               	 <div className="mt-3 text-center ">
                    {/* <div className="text-3xl font-medium text-gray-900">
                    	Sign Up
                    </div>
					<p className="text-lg text-gray-500">
					Create your account
					</p> */}
					<div className="md:flex">
						<div className="flex flex-col min-w-0 md:pr-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">First Name</label>
							<input value={fname} onChange={e=>onChange(e.target.value,'fname')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" placeholder="John" />
						</div>
						<div className="flex flex-col min-w-0 md:pl-2 flex-1">
							<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Last Name</label>
							<input value={lname} onChange={e=>onChange(e.target.value,'lname')} type="text" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block w-auto" placeholder="Smith" />
						</div>
					</div>
					<div className="flex flex-col">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Email Address</label>
						<input value={email} onChange={e=>onChange(e.target.value,'email')} focus="true" type="email" className="rounded-md text-lg px-4 py-2  border border-gray-300 " placeholder="john@smith.com" />
					</div>
					<div className="flex flex-col">
						<label className="text-gray-400 text-sm block mt-4 inline-block text-left">Password</label>
						<input value={password} onChange={e=>onChange(e.target.value,'password')} type="password" className="rounded-md text-lg px-4 py-2  border border-gray-300 inline-block" placeholder="*******" />
					</div>
					<br></br>

					<div className="flex flex-col">
			<button 
                type="submit" 
                disabled={isSigningUp}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
                {isSigningUp ? 'Signing Up...' : 'Sign Up'}
            </button>

						{isSigningUp && (
                <div className="mt-4">
                    <div className="relative pt-1">
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                            <div style={{ width: "100%" }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                        </div>
                    </div>
                </div>
            )}

					</div>
					<br></br>
					<div class="border border-solid">
					   {/* <Google  /> */}

					</div>
	
                    </div>
                  </form>
	  </>
	)
  })

export default Signup;