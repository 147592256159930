import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { FaVideo, FaVideoSlash, FaMicrophone, FaMicrophoneSlash, FaRobot, FaTimes, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import moment from 'moment';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const VoiceBar = ({ isActive, color }) => {
  const barCount = 10;
  const bars = Array.from({ length: barCount }, (_, index) => (
    <div
      key={index}
      className={`w-1 mx-px transition-all duration-100 ${color}`}
      style={{
        height: isActive ? `${Math.random() * 100}%` : '10%',
      }}
    ></div>
  ));

  return (
    <div className="flex items-end h-6 space-x-1">
      {bars}
    </div>
  );
};

const VirtualInterviewRoom = ({ store }) => {
  const [isReady, setIsReady] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [isAIVoiceOn, setIsAIVoiceOn] = useState(true);
  const [messages, setMessages] = useState([]);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isInterviewEnded, setIsInterviewEnded] = useState(false);
  const [isAiSpeaking, setIsAiSpeaking] = useState(false);
  const [isCandidateSpeaking, setIsCandidateSpeaking] = useState(false);
  const [interviewHistory, setInterviewHistory] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCandidateTurn, setIsCandidateTurn] = useState(false);
  const [aiResponse, setAiResponse] = useState('');
  const [isAiResponseComplete, setIsAiResponseComplete] = useState(true);
  const [stream, setStream] = useState(null);
  const [responseQueue, setResponseQueue] = useState([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [evaluation, setEvaluation] = useState(null);

  const videoRef = useRef(null);
  const chatRef = useRef(null);
  const utteranceRef = useRef(null);
  const pauseTimeoutRef = useRef(null);

  const { interviewLink } = useParams();
  const history = useHistory();
  const speechSynthesis = window.speechSynthesis;

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      setError('Browser does not support speech recognition.');
    }
  }, [browserSupportsSpeechRecognition]);

  useEffect(() => {
    const fetchInterviewDetails = async () => {
      try {
        const response = await store.api.get(`/user/InterviewDetails/${interviewLink}`);
        if (response.data.success) {
          setInterviewDetails(response.data.interviewDetails);
        } else {
          setError('Failed to fetch interview details. Please check your link and try again.');
        }
      } catch (error) {
        console.error('Error fetching interview details:', error);
        setError('An error occurred while loading the interview. Please try again later.');
      }
    };

    fetchInterviewDetails();
  }, [interviewLink, store.api]);

  useEffect(() => {
    if (isReady && isCameraOn) {
      startCamera();
    } else if (!isCameraOn) {
      stopCamera();
    }
  }, [isReady, isCameraOn]);

  const [transcriptBuffer, setTranscriptBuffer] = useState('');
  const [isLongPause, setIsLongPause] = useState(false);
  const longPauseTimeoutRef = useRef(null);
  
  const handleSpeechRecognition = (event) => {
    const currentTranscript = event.results[event.results.length - 1][0].transcript;
    
    setTranscriptBuffer(prevBuffer => prevBuffer + ' ' + currentTranscript);
    setIsCandidateSpeaking(true);
    
    // Clear any existing timeouts
    if (pauseTimeoutRef.current) clearTimeout(pauseTimeoutRef.current);
    if (longPauseTimeoutRef.current) clearTimeout(longPauseTimeoutRef.current);
    
    // Set a short pause timeout
    pauseTimeoutRef.current = setTimeout(() => {
      setIsCandidateSpeaking(false);
    }, 1000); // 1 second of silence to consider a short pause
    
    // Set a long pause timeout
    longPauseTimeoutRef.current = setTimeout(() => {
      setIsLongPause(true);
    }, 3000); // 3 seconds of silence to consider a long pause
  };
  
  useEffect(() => {
    if (isLongPause && transcriptBuffer.trim()) {
      handleCandidateResponse(transcriptBuffer.trim());
      setTranscriptBuffer('');
      setIsLongPause(false);
    }
  }, [isLongPause, transcriptBuffer]);
    

  // Add this to your component to start speech recognition
  useEffect(() => {
    if (isMicOn && !listening && isCandidateTurn && !isInterviewEnded) {
      SpeechRecognition.startListening({ continuous: true });
    } else if (!isMicOn || isInterviewEnded) {
      SpeechRecognition.stopListening();
    }
  }, [isMicOn, listening, isCandidateTurn, isInterviewEnded]);

  useEffect(() => {
    if (transcript) {
      handleSpeechRecognition({ results: [[ { transcript } ]] });
    }
  }, [transcript]);
  

  
  

  useEffect(() => {
    if (responseQueue.length > 0 && !isProcessingQueue) {
      processResponseQueue();
    }
  }, [responseQueue, isProcessingQueue]);
  
  
  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ 
        video: true, 
        audio: isMicOn 
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (err) {
      console.error("Error accessing media devices:", err);
      setError("Failed to access camera. Please check your device settings and try again.");
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const toggleCamera = () => setIsCameraOn(!isCameraOn);

  const toggleMic = () => {
    setIsMicOn(!isMicOn);
    if (!isMicOn) {
      SpeechRecognition.startListening({ continuous: true });
    } else {
      SpeechRecognition.stopListening();
    }
  };

  const handleSpeechEnd = (finalTranscript) => {
    if (pauseTimeoutRef.current) {
      clearTimeout(pauseTimeoutRef.current);
    }
    if (finalTranscript.trim()) {
      handleCandidateResponse(finalTranscript);
      resetTranscript();
    }
    setIsCandidateTurn(false);
    setIsCandidateSpeaking(false);
    // Handle pauses
    pauseTimeoutRef.current = setTimeout(() => {
      if (!finalTranscript.trim() && !isCandidateSpeaking) {
        generateAIResponse("It seems like you paused. Please continue when ready.");
      }
    }, 3000); // 3 seconds pause handling
  };

  const interruptAI = () => {
    if (isAiSpeaking) {
      speechSynthesis.cancel();
      setIsAiSpeaking(false);
      setIsAiResponseComplete(true);
      setIsCandidateTurn(true);
    }
  };


// Add this function to process the queue
const processResponseQueue = async () => {
  if (responseQueue.length > 0 && !isProcessingQueue) {
    setIsProcessingQueue(true);
    const response = responseQueue[0];
    
    await speakAIResponse(response);
    
    setResponseQueue(prevQueue => prevQueue.slice(1));
    setIsProcessingQueue(false);
    
    // Process next item in queue if any
    if (responseQueue.length > 1) {
      processResponseQueue();
    }
  }
};


const speakAIResponse = async (text) => {
  if (isAIVoiceOn) {
    const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [text];
    
    for (let sentence of sentences) {
      try {
        // Request TTS audio from the server
        const response = await store.api.post('/ai/tts', { text: sentence }, { responseType: 'blob' });
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        await new Promise((resolve) => {
          audio.onplay = () => {
            setIsAiSpeaking(true);
            SpeechRecognition.stopListening();
          };

          audio.onended = () => {
            URL.revokeObjectURL(audioUrl);
            setIsAiSpeaking(false);
            resolve();
          };

          audio.play();
        });

        // Add a small pause between sentences
        await new Promise(resolve => setTimeout(resolve, 300));
      } catch (error) {
        console.error('Error with server-side TTS:', error);
        await fallbackToSpeechSynthesis(sentence);
      }
    }
    
    setIsAiResponseComplete(true);
    setIsCandidateTurn(true);
    if (isMicOn && !isInterviewEnded) {
      setTimeout(() => SpeechRecognition.startListening({ continuous: true }), 500);
    }
  }
};

const fallbackToSpeechSynthesis = (text) => {
  return new Promise((resolve) => {
    if (speechSynthesis) {
      const utterance = new SpeechSynthesisUtterance(text);

      utterance.onstart = () => {
        setIsAiSpeaking(true);
        SpeechRecognition.stopListening();
      };

      utterance.onend = () => {
        setIsAiSpeaking(false);
        resolve();
      };

      speechSynthesis.speak(utterance);
    } else {
      console.error('Speech synthesis not available');
      resolve();
    }
  });
};

// Add this useEffect to handle queue processing

  const generateAIResponse = async (prompt) => {
    setIsProcessing(true);
    try {
      const response = await store.api.post('/ai/next-question', {
        prompt: prompt,
        interviewHistory: interviewHistory,
        jobDescription: interviewDetails.jobDescription,
        candidateName:  interviewDetails.candidateName
      });
  
      const aiMessage = response.data.message;
      setAiResponse(aiMessage);
      setIsAiResponseComplete(false);
      
      // Add response to queue instead of speaking immediately
      setResponseQueue(prevQueue => [...prevQueue, aiMessage]);
      
      // Start processing queue if not already processing
      if (!isProcessingQueue) {
        processResponseQueue();
      }
  
      setInterviewHistory(prevHistory => [...prevHistory, { role: 'assistant', content: aiMessage }]);
      setMessages(prevMessages => [...prevMessages, { sender: 'AI Assistant', text: aiMessage, timestamp: new Date() }]);
    } catch (error) {
      console.error('Error generating AI response:', error);
      setError('Failed to generate AI response. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCandidateResponse = (response) => {
    if (response.trim()) {
      const messageObj = {
        sender: 'Candidate',
        text: response,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, messageObj]);
      setInterviewHistory((prevHistory) => [...prevHistory, { role: 'user', content: response }]);
  
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
  
      const prompt = `The candidate responded: "${response}".`;
      generateAIResponse(prompt);
    }
  };
  
  const handleStartInterview = async () => {
    setIsReady(true);
    setIsCameraOn(true);
    setIsMicOn(true);
    const startPrompt = `You are an AI interviewer. Start the interview for the position of ${interviewDetails.jobName}.`;
    await generateAIResponse(startPrompt);
  };

  const handleEndInterview = async () => {
    stopCamera();
    SpeechRecognition.stopListening();
    setIsInterviewEnded(true);
    const endPrompt = 'Thank the candidate and conclude the interview.';
    await generateAIResponse(endPrompt);
    history.push('/');
  };
  
  const saveInterview = async (evaluation) => {
    try {
      const response = await store.api.post('/user/interview/save', {
        'jobId': interviewDetails.jobId,
        'candidateName': interviewDetails.candidateName,
        'candidateEmail': interviewDetails.candidateEmail,
        'jobName': interviewDetails.jobName,
        'transcript': messages, // This is the array of messages from the interview
        'evaluation': evaluation, // The evaluation object returned from the AI
        'interviewDate' : new Date()
      });
  
      if (response.data.message === 'Interview feedback saved successfully') {
        console.log('Interview saved successfully');
        // You might want to update your UI here to reflect the successful save
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error saving interview:', error);
      setError('Failed to save interview data. Please try again.');
    }
  };
  
  const confirmExit = async () => {
    setIsExitDialogOpen(false);
    setIsExiting(true);
    try {
      // Generate AI evaluation
      const evaluationResponse = await store.api.post('/ai/evaluate-interview', {
        'interviewHistory': interviewHistory,
        'jobDescription': interviewDetails.jobDescription,
        'candidateName' : interviewDetails.candidateName
      });
      
      const evaluation = evaluationResponse.data;
      setEvaluation(evaluation);
  
      // Save interview data
      await saveInterview(evaluation);
  
      // End the interview
      setIsInterviewEnded(true);
      stopCamera();
      SpeechRecognition.stopListening();
      
      // Show evaluation result
      setAiResponse(`Interview Evaluation:\n\nSuitable for the job: ${evaluation.isSuitable ? 'Yes' : 'No'}\n\nComments: ${evaluation.explanation}`);
      
      // Navigate to the dashboard or a results page
      history.push('/login');
    } catch (error) {
      console.error('Error during interview exit:', error);
      setError('Failed to save interview data. Please try again.');
    } finally {
      setIsExiting(false);
    }
  };

  
  const handleExitInterview = () => {
    setIsExitDialogOpen(true);
  };
  const ConfirmationDialog = ({ isOpen, message, onConfirm, onCancel }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
          <h2 className="text-xl font-bold text-gray-800 mb-4">Confirm Exit</h2>
          <p className="text-gray-600 mb-6">{message}</p>
          <div className="flex justify-end space-x-4">
            <button 
              onClick={onCancel}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-300"
            >
              Cancel
            </button>
            <button 
              onClick={onConfirm}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
            >
              Exit Interview
            </button>
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Main content area */}
      <div className="flex-1 flex flex-col">
        {/* Video area */}
        <div className="flex-1 bg-gray-800 relative">
          {isReady ? (
            <video ref={videoRef} autoPlay muted className="w-full h-full object-cover" />
          ) : (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center p-8 bg-white rounded-lg shadow-md">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Welcome to the Virtual Interview</h2>
                {interviewDetails && (
                  <div className="text-gray-600 mb-6">
                    <p className="mb-2">Candidate: {interviewDetails.candidateName}</p>
                    <p className="mb-2">Position: {interviewDetails.jobName}</p>
                    <p className="mb-2">Date: {moment(interviewDetails.interviewDate).format('MMMM D, YYYY')}</p>
                    <p>Time: {interviewDetails.interviewTime}</p>
                  </div>
                )}
                <button
                  onClick={handleStartInterview}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  Start Interview
                </button>
              </div>
            </div>
          )}
          
          {/* Voice bars and turn indicator */}
          <div className="absolute top-4 left-4 right-4 flex justify-between items-center">
            <div className="flex flex-col items-center">
              <span className="text-white text-sm mb-1">AI</span>
              <VoiceBar isActive={isAiSpeaking} color="bg-blue-500" />
            </div>
            <div className="flex flex-col items-center">
              <span className="text-white text-sm mb-1">You</span>
              <VoiceBar isActive={listening} color="bg-green-500" />
            </div>
          </div>
          
          {/* Controls overlay */}
          {isReady && (
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4">
              <button onClick={toggleCamera} className="p-2 bg-gray-700 rounded-full text-white hover:bg-gray-600 transition duration-300">
                {isCameraOn ? <FaVideo /> : <FaVideoSlash />}
              </button>
              <button onClick={toggleMic} className="p-2 bg-gray-700 rounded-full text-white hover:bg-gray-600 transition duration-300">
                {isMicOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
              </button>
              <button onClick={() => setIsAIVoiceOn(!isAIVoiceOn)} className="p-2 bg-gray-700 rounded-full text-white hover:bg-gray-600 transition duration-300">
                {isAIVoiceOn ? <FaVolumeUp /> : <FaVolumeMute />}
              </button>
              <button onClick={handleEndInterview} className="p-2 bg-red-500 rounded-full text-white hover:bg-red-600 transition duration-300">
                <FaTimes />
              </button>
            </div>
          )}
        </div>
        
        {/* Chat/Notes section */}
        <div className="h-1/3 bg-white border-t border-gray-200 flex flex-col">
          <div ref={chatRef} className="flex-1 overflow-y-auto p-4 space-y-2">
            {messages.map((msg, index) => (
              <div key={index} className={`p-2 rounded ${msg.sender === 'Candidate' ? 'bg-blue-100 ml-auto' : 'bg-gray-100'} max-w-3/4`}>
                <div className="font-bold">{msg.sender}</div>
                <div>{msg.text}</div>
                <div className="text-xs text-gray-500">{moment(msg.timestamp).format('HH:mm')}</div>
              </div>
            ))}
          </div>
          {transcript && (
            <div className="p-2 bg-yellow-100 rounded">
              <div className="font-bold">Current Transcript:</div>
              <div>{transcript}</div>
            </div>
          )}
        </div>
      </div>
      
      {/* AI Assistant Panel */}
      <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
        <div className="bg-white rounded-lg p-4 shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <FaRobot className="mr-2" /> AI Assistant
          </h3>
          <p>{aiResponse}</p>
          {isProcessing && <p className="text-sm text-gray-500 mt-2">Processing...</p>}
          {!isAiResponseComplete && <p className="text-sm text-blue-500 mt-2">Speaking...</p>}
          {isCandidateTurn && <p className="text-sm text-green-500 mt-2">Listening to you...</p>}
        </div>
        {/* {isInterviewEnded && (
          <button
            onClick={() => history.push('/')}
            className="mt-4 w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
          >
            Exit Interview
          </button>
        )} */}
           <button
        onClick={handleExitInterview}
        disabled={isExiting}
        className="w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300 disabled:bg-gray-400"
      >
        {isExiting ? 'Exiting...' : 'Exit Interview'}
      </button>
      </div>
      <ConfirmationDialog
      isOpen={isExitDialogOpen}
      message="Are you sure you want to exit the interview? This action cannot be undone."
      onConfirm={confirmExit}
      onCancel={() => setIsExitDialogOpen(false)}
    />
      {/* Error Modal */}
      {error && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
            <p className="text-gray-700 mb-4">{error}</p>
            <button 
              onClick={() => history.push('/')} 
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Return to Dashboard
            </button>
          </div>
        </div>
      )}
    </div>
  )};
export default inject('store')(observer(VirtualInterviewRoom));