import React from 'react';
import Header from './Header';
import Sidebar from './LeftSidebar';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-hidden bg-gray-50">
        <Header />
        <main className="flex-1 overflow-x-hidden overflow-y-auto p-6">
          <div className="container mx-auto">
            {children}
          </div>
        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;