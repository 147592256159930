import React from 'react';
import Layout from '../../Layout/Layout';
import InterviewScheduling from './InterviewScheduling';

const InterviewSchedulingPage = () => {
  return (
    <Layout>
      <InterviewScheduling />
    </Layout>
  );
};

export default InterviewSchedulingPage;