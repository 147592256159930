import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Write Article",
	desc: "Write article on the provided topic",
	category: "Content",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "gray-500",
	toColor: "gray-500",

	to: "/ai/article",
	api: "/ai/article",

	output: {
		title: "Article",
		desc: "The following key points detected",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Entry Text",
		desc: "Provide topic on which you want to write article.",
		// n: 1,
		prompts: [{ 
				title: "Content", 
				attr: "content",  
				value: "", 
				placeholder: "Enter topic for the Article", 
				label: "",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Freelancing",
			},
		],
		example: {
			output: "Freelancing is a form of self-employment that has become increasingly popular in recent years. It allows individuals to work on their own terms, from the comfort of their own home or office, and to choose the type of work they want to do. Freelancers are typically independent contractors who are hired by companies or individuals for specific projects or tasks. They often specialize in a particular field, such as web design, writing, marketing, programming, graphic design and more. ",
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

