import React from 'react';
import Layout from '../Layout/Layout';
import WorkflowGuide from './WorkflowGuide';

const WorkflowGuidePage = () => {
  return (
    <Layout>
      <WorkflowGuide />
    </Layout>
  );
};

export default WorkflowGuidePage;