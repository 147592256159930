import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';

const ManageSubscription = inject('store')(observer(({ store }) => {
  const [currentPlan, setCurrentPlan] = useState('Free');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCurrentPlan();
  }, []);

  const fetchCurrentPlan = async () => {
    try {
      const response = await store.api.post("/user/stripe/plan");
      setCurrentPlan(response.data.plan);
    } catch (error) {
      console.error("Error fetching current plan:", error);
    }
  };

  const handleUpgrade = async () => {
    setLoading(true);
    try {
      const response = await store.api.post("/user/stripe/subscribe", { priceId: 'price_xxxxx', trial: false });
      window.location.href = response.data; // Redirect to Stripe Checkout
    } catch (error) {
      console.error("Error upgrading plan:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    setLoading(true);
    try {
      await store.api.post("/user/stripe/cancel");
      alert("Your subscription will be canceled at the end of the current billing period.");
      fetchCurrentPlan();
    } catch (error) {
      console.error("Error canceling subscription:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleManageBilling = async () => {
    try {
      const response = await store.api.post("/user/stripe/customer-portal");
      window.location.href = response; // Redirect to Stripe Customer Portal
    } catch (error) {
      console.error("Error opening customer portal:", error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Manage Your Subscription</h1>
        
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-2">
          <PlanTile
            title="Free Plan"
            price="$0"
            features={[
              'Basic features',
              'Limited usage',
              'Community support',
            ]}
            current={currentPlan === 'Free'}
            onSelect={() => {}}
            disabled={true}
          />
          <PlanTile
            title="Pro Plan"
            price="$29"
            features={[
              'Advanced features',
              'Unlimited usage',
              'Priority support',
              'API access',
            ]}
            current={currentPlan === 'Pro'}
            onSelect={currentPlan === 'Free' ? handleUpgrade : handleCancel}
            disabled={loading}
            buttonText={currentPlan === 'Free' ? 'Upgrade Plan' : 'Cancel Plan'}
          />
        </div>

        {currentPlan === 'Pro' && (
          <div className="mt-10 text-center">
            <button
              onClick={handleManageBilling}
              disabled={loading}
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              Manage Billing
            </button>
          </div>
        )}
      </div>
    </div>
  );
}));

const PlanTile = ({ title, price, features, current, onSelect, disabled, buttonText }) => (
  <div className={`rounded-lg shadow-md divide-y divide-gray-200 ${current ? 'border-2 border-blue-500' : ''}`}>
    <div className="p-6">
      <h2 className="text-2xl leading-6 font-semibold text-gray-900">{title}</h2>
      <p className="mt-4">
        <span className="text-4xl font-extrabold text-gray-900">{price}</span>
        <span className="text-base font-medium text-gray-500">/month</span>
      </p>
      {!current && (
        <button
          onClick={onSelect}
          disabled={disabled}
          className={`mt-8 block w-full bg-blue-500 border border-blue-500 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-blue-600 ${
            disabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {buttonText || 'Select Plan'}
        </button>
      )}
      {current && (
        <div className="mt-8 block w-full bg-green-500 border border-green-500 rounded-md py-2 text-sm font-semibold text-white text-center">
          Current Plan
        </div>
      )}
    </div>
    <div className="pt-6 pb-8 px-6">
      <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">What's included</h3>
      <ul className="mt-6 space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex space-x-3">
            <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
            <span className="text-sm text-gray-500">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default ManageSubscription;