import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import {Rings} from 'react-loader-spinner';

const FileUpload = ({ uploadedFiles, onDrop, handlePreviewClick, isLoading }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: ['.pdf', '.doc', '.docx'],
    onDrop,
  });

  return (
    <div {...getRootProps()} className="w-72 h-32 border-4 border-dashed rounded-lg text-center cursor-pointer">
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" className="text-gray-600 mb-2" />
      <p className="text-sm text-gray-600">Upload or Drag & Drop CV</p>
      {uploadedFiles.length > 0 && (
        <div className="mt-4">
          <h3 className="text-sm font-semibold">Uploaded Files:</h3>
          <ul className="list-disc list-inside">
            {uploadedFiles.map((file, index) => (
              <li key={index}>
                {file.name}{' '}
                <button className="text-lm text-purple-700" onClick={() => handlePreviewClick(file)} title="Preview" style={{ marginLeft: '10px' }}>
                  <FontAwesomeIcon icon={faEye} size="lg" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {isLoading && (
        <div className="loader">
          <Rings type="ThreeDots" color="#00BFFF" height={80} width={80} />
        </div>
      )}
    </div>
  );
};

export default FileUpload;