import React from 'react';
import { observer, inject } from 'mobx-react';

import Layout from './Components/Layout/Layout';
import ScreenCandidate from './Components/ScreenCandidate';
import ResponseTile from '../src/Components/TableTile';

const Dashboard = ({ store }) => {
	return (
		<React.Fragment>
			<Layout>
				<div className="container mx-auto py-8">
					<ScreenCandidate />
					<hr className="my-8 border-t-2 border-white-300" />
					<ResponseTile />
				</div>
			</Layout>
		</React.Fragment>
	);
};

export default inject('store')(observer(Dashboard));