import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';
import Layout from './Components/Layout/Layout';
import ScreenCandidate from './Components/ScreenCandidate';
import Output from './Components/Output';
import Logo from './Logo';

const Screening = ({ store }) => {
  const showOutputs = store.inProgress || store.storeOutput || store.scoreOutput || store.jobAnalysis;

  return (
    <Layout>
      <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* <div className="mb-6">
            <Link to="/" className="text-blue-600 hover:text-blue-800 flex items-center">
              <FaChevronLeft className="mr-2" />
              Back to Workflow
            </Link>
          </div>

          <h1 className="text-3xl font-bold text-gray-900 mb-6">Candidate Screening</h1> */}

          <div className="bg-white shadow-lg rounded-xl overflow-hidden mb-8">
            <div className="px-6 py-8">
              <ScreenCandidate />
            </div>
          </div>

          {showOutputs && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div>
                <Output 
                  title="Resume Information"
                  desc="Key information extracted from resume."
                  Icon={Logo}
                  fromColor="indigo-300"
                  toColor="blue-400"
                  loading={store.inProgress}
                  output={store.storeOutput}
                />
              </div>
              <div className="space-y-8">
                <Output 
                  title="AI Resume Scoring"
                  desc="Scores CV on a scale of 1-5,where 5 is highest and 1 being lowest match."
                  Icon={Logo}
                  fromColor="indigo-300"
                  toColor="blue-400"
                  loading={store.inProgress}
                  output={store.scoreOutput}
                />
                <Output 
                  title="Job Description Analysis"
                  desc="Summarizing the job description."
                  Icon={Logo}
                  fromColor="indigo-300"
                  toColor="blue-400"
                  loading={store.inProgress}
                  output={store.jobAnalysis}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default inject('store')(observer(Screening));