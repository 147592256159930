import React from 'react';
import { observer, inject } from 'mobx-react';
import { CheckIcon, DuplicateIcon, ExclamationCircleIcon } from '@heroicons/react/outline';

const Output = inject('store')(observer(({ store, title, desc, Icon, output, loading, fromColor, toColor }) => {
  let parsedData;

  if (output) {
    try {
      parsedData = JSON.parse(output);
    } catch (error) {
      console.error(`Error parsing JSON data: ${error}`);
      parsedData = output;
    }
  }

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-8">
      <div className={`bg-gradient-to-r from-${fromColor || 'blue-500'} to-${toColor || 'indigo-600'} px-6 py-4`}>
        <div className="flex items-center">
          {Icon && <Icon className="h-6 w-6 text-white mr-3" aria-hidden="true" />}
          <div>
            <h3 className="text-lg font-semibold text-white">{title}</h3>
            <p className="text-sm text-blue-100">{desc}</p>
          </div>
        </div>
      </div>
      <div className="px-6 py-4">
        {loading ? (
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div className="space-y-2">
            {parsedData && typeof parsedData === 'object' && Object.entries(parsedData).map(([key, value]) => (
              <div key={key} className="flex flex-col sm:flex-row sm:items-center py-2 border-b border-gray-200 last:border-b-0">
                <span className="text-sm font-medium text-gray-500 sm:w-1/3">{key}</span>
                <span className="text-sm text-gray-900 sm:w-2/3">
                  {Array.isArray(value) ? value.join(', ') : value}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <QuickTools store={store} output={output} />
    </div>
  );
}));

const QuickTools = inject('store')(observer(({ store, output }) => {
  if (!output) return null;

  return (
    <div className="bg-gray-50 px-6 py-3 flex justify-end space-x-2">
      <button
        onClick={() => store.copyToClipboard(output)}
        className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
        title="Copy to clipboard"
      >
        <DuplicateIcon className="w-5 h-5" />
      </button>
      {/* <button
        onClick={() => store.reportToFeedback(output)}
        className="text-gray-600 hover:text-red-600 transition-colors duration-200"
        title="Report issue"
      >
        <ExclamationCircleIcon className="w-5 h-5" />
      </button> */}
    </div>
  );
}));

export default Output;