import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import Layout from './Layout/Layout';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { observer, inject } from 'mobx-react';
import TableTile from './TableTile';
import Output from '../Components/Output'
import Logo from '../Logo'

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom'; // Add this import
import { FaChevronLeft } from 'react-icons/fa'; // Import the left chevron icon


const CandidateDetails = ({ store }) => {

  const [selectedJobId, setSelectedJobId] = useState(null);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [jobAnalysis, setjobAnalysis] = useState(null);
  const [scoreData, setScoreData] = useState(null);




  const dt = useRef(null);

  const refreshJobList = async () => {
    let response = await store.api.post('/user/JobDetails/view');
    store.joblist = response.data.map((job) => ({
      jobid: job.jobId,
      jobname: job.jobname,
      jobdescription: job.jobdescription,
    }));
  };

  useEffect(() => {
    setLoading(true);
    refreshJobList();
  }, []);

  useEffect(() => {
    if (selectedJobId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [selectedJobId]);
  const fetchData = async () => {
    try {
      setLoading(true);
  
      if (selectedJobId) {
        const response = await store.api.post('/user/CandidateDetails/view', { jobid: selectedJobId });
        setData(response.data);
        console.log("response.data",response.data);
        setTotalRecords(response.data.length);
      }
  
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  
  const handleJobIdChange = (e) => {
    setSelectedJobId(e.value);
  };

  const deleteCandidate = async () => {
        try {
            await store.api.post('/user/CandidateDetails/delete', { jobid: selectedJobId });
           // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Job Deleted', life: 3000 });
            setDeleteDialog(false);
            fetchData();
        } catch (error) {
            console.error(error);
        
    }
  }

  const getCandidateScore = async (resumeId) => {
    try {
    const scoreResponse= await store.api.post('/user/CandidateDetails/score', { resumeId: resumeId });
     setScoreData( scoreResponse.data)    ;
      
    } catch (error) {
        console.error(error);
    
}
}


const showJobAnalysis = async () => {
  try {
  const  response=  await store.api.post('/user/JobDetails/viewAnalysis', { jobid: selectedJobId });

  setjobAnalysis(JSON.stringify(response.data));
} catch (error) {
      console.error(error);
  
}
}

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  
  const onRowSelect = async (event) => {
    showJobAnalysis();

    const selectedName = event.value[0].Name;
    const resumeId=event.value[0].resumeId;
    console.log("ResumeID" ,event.value[0].resumeId);
    try {
      const response = await store.api.post('user/CandidateDetails/filter', {
        
        jobid: selectedJobId,
        property: 'Name',
        value: selectedName,
        resumeId:resumeId
      });
      setSelectedRow(JSON.stringify(response.data));
     // store.storeOutput = JSON.stringify(response.data);
     getCandidateScore(event.value[0].resumeId);
    } catch (error) {
      console.error(error);
    }
  };

  const onRowUnselect = (event) => {
    setSelectedRows(event.data);
  };

  const confirmDeleteJob = (rows) => {
    setDeleteDialog(true);
};

const exportToExcel = () => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const exceldata = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
  saveAs(exceldata, 'CandidateList.xlsx');
};

const showEditJob = (rows) => {
   // setEditJob(true);
};

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button
          label="Export to CSV"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={() => {exportCSV()}}
          style={{ marginRight: '1em' }}
        />
        <Button
          label="Export Excel"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportToExcel}
        />
      </>
    );
  };
const exportCSV = () => {
  dt.current.exportCSV();
 

};



const actionBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
      <Button key="editButton" icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => (rowData)} />
      <Button key="deleteButton" icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteJob()} />
    </React.Fragment>        );
};

const deleteDialogFooter = (
  <React.Fragment>
      <Button key="CancelDelete" label="No" icon="pi pi-times" className="p-button-text" onClick={deleteCandidate} />
      <Button key="Deletebutton" label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCandidate} />
  </React.Fragment>
);
  

  const paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown';
  
  return (
    <Layout>
      <div className="bg-gray-100 min-h-screen">
        <div className="container mx-auto py-8 px-4">
        <div className="mb-6">
            <Link to="/" className="text-blue-600 hover:text-blue-800 flex items-center">
              <FaChevronLeft className="mr-2" />
              Back to Workflow
            </Link>
          </div>

          
          <div className="bg-white shadow-md rounded-lg p-6 mb-8 border border-gray-300">
            
            <div className="mb-6">
              <label htmlFor="jobId" className="block text-sm font-medium text-gray-700 mb-2">
                Select Job
              </label>
              <Dropdown
                id="jobId"
                value={selectedJobId}
                options={store.joblist.map((job) => ({ value: job.jobid, label: job.jobname }))}
                onChange={handleJobIdChange}
                placeholder="Select a job"
                className="w-full md:w-1/3 border border-gray-300 rounded-md"
              />
            </div>

            <Toolbar className="mb-4 p-3 bg-gray-200 rounded-md border border-gray-300" right={rightToolbarTemplate} />

            <div className="overflow-x-auto">
              <DataTable
                value={data}
                ref={dt}
                paginator
                rows={rows}
                totalRecords={totalRecords}
                loading={loading}
                first={first}
                onPageChange={onPageChange}
                paginatorTemplate={paginatorTemplate}
                emptyMessage="No records found"
                className="p-datatable-sm border border-gray-300 rounded-md"
                selectionMode="multiple"
                selection={selectedRows}
                onSelectionChange={onRowSelect}
                onRowUnselect={onRowUnselect}
                rowHover
                stripedRows
              >
                <Column field="Score" header="Score" sortable className="p-column-title" />

                <Column field="Name" header="Name" sortable className="p-column-title" />
                <Column field="Emails" header="Emails" sortable className="p-column-title" />
                <Column field="Phone Number" header="Phone Number" sortable className="p-column-title" />
                <Column field="Total YearsOf Experience" header="Experience" sortable className="p-column-title" />
                <Column field="Highest Qualification" header="Qualification" sortable className="p-column-title" />
                <Column body={actionBodyTemplate} exportable={false} style={{ width: '120px' }} />

              </DataTable>
            </div>
          </div>

          {selectedRow && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <Output 
                title="Resume Information"
                desc="Key information extracted from resume."
                Icon={Logo}
                fromColor="blue-300"
                toColor="blue-400"
                output={selectedRow}
              />
              <div className="space-y-8">
                <Output 
                  title="Candidate Score"
                  desc="Analysis of candidate suitability for the job."
                  Icon={Logo}
                  fromColor="green-300"
                  toColor="green-400"
                  output={scoreData}
                />
                <Output 
                  title="Job Description Analysis"
                  desc="Summary of the job description."
                  Icon={Logo}
                  fromColor="purple-300"
                  toColor="purple-400"
                  output={jobAnalysis}
                />
              </div>
            </div>
          )}

          <Dialog 
            visible={deleteDialog} 
            header="Confirm Deletion" 
            modal 
            footer={deleteDialogFooter}
            onHide={() => setDeleteDialog(false)}
            className="w-1/2"
          >
            <div className="confirmation-content flex items-center">
              <i className="pi pi-exclamation-triangle mr-3 text-3xl text-yellow-500" />
              <span className="text-lg">
                Are you sure you want to delete the selected candidate(s)?
              </span>
            </div>
          </Dialog>
        </div>
      </div>
    </Layout>
  );
};

export default inject('store')(observer(CandidateDetails));
