import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTh, FaUsers,FaSearch, FaBriefcase, FaClipboardCheck, FaChevronRight, FaCoins,FaVideo } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';

const Sidebar = ({ store }) => {
  const [isHovered, setIsHovered] = useState(false);

  const navItems = [
    { to: "/", icon: FaTh, label: "Dashboard" },
    { to: "/Screening", icon: FaSearch, label: "Screening" },
    { to: "/candidate-details", icon: FaUsers, label: "Candidates" },
    { to: "/Manage-Jobs", icon: FaBriefcase, label: "Jobs" },
    { to: "/Assesment", icon: FaClipboardCheck, label: "Assessment" },
    { to: "/virtual-interview", icon: FaVideo, label: "Virtual Interview" },



  ];

  return (
    <div 
      className={`bg-gradient-to-b from-blue-600 to-indigo-800 h-screen ${isHovered ? 'w-52' : 'w-12'} transition-all duration-300 ease-in-out flex flex-col`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center justify-end p-3">
        <FaChevronRight className={`text-white transition-transform duration-300 ${isHovered ? 'rotate-180' : ''}`} size={16} />
      </div>

      <nav className="flex-1 overflow-y-auto py-3 px-2">
        {navItems.map((item) => (
          <NavLink
            key={item.to}
            to={item.to}
            className={({ isActive }) =>
              `flex items-center py-2 px-2 my-1 rounded-lg transition duration-200 ${
                isActive
                  ? "bg-white bg-opacity-20 text-white"
                  : "text-blue-100 hover:bg-white hover:bg-opacity-10 hover:text-white"
              }`
            }
          >
            <item.icon className={`h-4 w-4 ${!isHovered ? 'mx-auto' : 'mr-2'}`} />
            {isHovered && <span className="font-medium text-xs">{item.label}</span>}
          </NavLink>
        ))}
      </nav>
      
      <div className="p-3">
        <div className={`bg-white bg-opacity-10 rounded-lg p-2 ${!isHovered ? 'text-center' : ''}`}>
          <FaCoins className={`text-yellow-300 text-lg ${!isHovered ? 'mx-auto' : 'inline mr-2'}`} />
          {isHovered && (
            <>
              <span className="text-blue-100 text-xs">Tokens</span>
              <span className="text-white text-xs font-bold ml-1">{store.profile.credits}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject('store')(observer(Sidebar));